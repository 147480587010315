import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { updateComponentFields } from '@redux/AIRedux/aiBasedSlice';

const Dropdown = ({ componentDetails, componentOptions }) => {
    const { is_required = 0, is_multiple = 0, options = [] } = componentOptions;
    const { dependentField } = useSelector(state => state.form);
    const { data } = useSelector(state => state.aiServiceData);
    const dispatch = useDispatch();

    const { componentName = null, componentCode = null } = componentDetails;

    const [selected, setSelected] = useState(data[componentCode] || '');

    const submitHandler = e => {
        e.preventDefault();

        setSelected(e.target.value);

        dispatch(
            updateComponentFields({
                name: componentCode,
                value: e.target.value
            })
        );
    };

    const compOptions = dependentField?.[componentName]?.values || options;

    return (
        <>
            <Form.Label>{componentName}</Form.Label>
            <Form.Control
                as="select"
                aria-label={componentName}
                required={parseInt(is_required)}
                multiple={parseInt(is_multiple ? is_multiple : 0)}
                value={selected}
                onChange={submitHandler}
            >
                <option value="">{`Select ${componentName.replace(/_/g, ' ')}`}</option>
                {Object.keys(compOptions).map(key => {
                    return (
                        <option key={key} value={compOptions[key]}>
                            {compOptions[key]}
                        </option>
                    );
                })}
            </Form.Control>
        </>
    );
};
export default Dropdown;
