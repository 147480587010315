function VideoTutorial({url}) {
    return (
        <>
            <div className="video-tutorial m-4">
                <h2 className='fs-3 text-center'>Self Inspection Video</h2>
                <div className="video-content rounded-4">
                    <div className="video-wrapper d-flex justify-content-center">
                      <iframe width="560" height="315" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VideoTutorial;
