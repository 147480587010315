import { Fragment } from 'react';
import TickSign from './TickSign';
import TakeVideo from './TakeVideo';
import { Form } from 'react-bootstrap';
import VideoUpload from './VideoUpload';
import SampleVideo from './SampleVideo';
import { useSelector, useDispatch } from 'react-redux';
import { resetVideos, updateVideos } from '@redux/AIRedux/aiBasedSlice';
const CommonVideo = ({ componentOptions, componentDetails }) => {
    const { upload_source } = componentOptions;
    const { componentName, componentCode } = componentDetails;
    const { videos } = useSelector(state => state.aiServiceData);
    const { videos: videosSlice } = useSelector(state => state.aiBased);

    const dispatch = useDispatch();
    const handleVideoChange = e => {
        dispatch(resetVideos({ name: componentCode }));
        Array.from(e.target.files).forEach(file => {
            dispatch(
                updateVideos({
                    name: componentCode,
                    video: file
                })
            );
        });
    };
    return (
        <div>
            <Fragment>
                <Form.Label>{componentName}</Form.Label>
                {upload_source == 'c' ? (
                    <div className="d-flex gap-1 ">
                        <div className="m-auto">
                            <TakeVideo handleVideoChange={handleVideoChange} />
                        </div>
                        {(videos[componentCode] || videosSlice[componentCode]) && <TickSign />}
                    </div>
                ) : (
                    <VideoUpload handleVideoChange={handleVideoChange} />
                )}
            </Fragment>
        </div>
    );
};
export default CommonVideo;
