import React from 'react';
import { useSelector } from 'react-redux';
const PageCount = ({ screenNo, screenName, screenCount }) => {
    const { form } = useSelector(state => state.form);
    return (
        <div className="pagecount d-flex justify-content-between align-items-end py-3">
            <div className="page-title flex-grow-1 text-white">
                <h4 className="mb-0">{form[0].bucket_name}</h4>
                <div className="fs-5">{screenName}</div>
            </div>
            <div className="number-of-pages text-center text-white py-1 rounded">
                {screenNo}/<span>{screenCount}</span>
            </div>
        </div>
    );
};

export default PageCount;
