import axios from 'axios';
import { useParams } from 'react-router-dom';
import Tutorials from '../Tutorials/Tutorials';
import { useState, useEffect } from 'react';
import OTPModel from '../Modals/OTPModal';
import { useHistory } from 'react-router-dom';
import PerformInspection from '../Dashboard/PerformInspection';

const Home = () => {
    const history = useHistory();
    const params = useParams();
    const [maskedNumber, setMaskedNumber] = useState('');
    const [otpPopUp, setOtpPopUp] = useState(false);

    const submitHandler = async () => {
        const { data } = await axios.post(`/orders/${params.orderId}/otp/send`);
        if (data.StatusCode == 1) {
            setMaskedNumber(data.Result.masked_phone);
        }
    };
    useEffect(() => {
        if (maskedNumber) {
            setOtpPopUp(true);
        }
    }, [maskedNumber]);

    const handleVerify = async otp => {
        const { data } = await axios.post(`/orders/${params.orderId}/otp/verify`, {
            otp
        });
        if (data.StatusCode == 1) {
            axios.post(`/orders/${params.orderId}/inspection/accept`).then(res => {
                if (res.data.StatusCode == 1) {
                    history.push('start');
                }
            });
        }
    };
 
    const handleClose = () =>{
        setOtpPopUp(false);
        setMaskedNumber('');
    }
    return (
        <>
            {otpPopUp ? (
                <OTPModel number={maskedNumber} handleClose={handleClose} handleVerify={handleVerify} submitHandler={submitHandler} />
            ) : (
                <div className="home p-4">
                    <PerformInspection submit={submitHandler} />
                    <Tutorials />
                </div>
            )}
        </>
    );
};

export default Home;
