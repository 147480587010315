import { useState, useEffect, useRef } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateComponentFields } from '@redux/AIRedux/aiBasedSlice';

const TextInput = ({ componentOptions, componentDetails }) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [validationError, setValidationError] = useState('');
    const { is_required = 0 } = componentOptions;
    const { componentName = null, componentCode = null } = componentDetails;

    const { data } = useSelector(state => state.aiServiceData);

    const [value, setValue] = useState(data[componentCode] || '');

    const handleChange = e => {
        setValue(e.target.value);
        setValidationError(ref.current.validationMessage);

        dispatch(
            updateComponentFields({
                name: componentCode,
                value: e.target.value
            })
        );
    };

    useEffect(() => {
        setValidationError(ref.current.validationMessage);
    }, [ref]);

    return (
        <Form.Group>
            <Form.Label>{componentName}</Form.Label>
            <FormControl ref={ref} type="text" value={value} onChange={handleChange} required={is_required} />
            <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default TextInput;
