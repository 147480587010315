import React from 'react';
import { Card } from 'react-bootstrap';
const OrderBody = ({ orderNumber, vehicleDetails, customerName, customerAddress, inspectionStart, inspectionCompleted }) => {
    return (
        <Card className="border-0">
            <Card.Title className="py-4 card-header-bg-blue text-center">
                <Card.Img
                    variant="top"
                    src="https://cdn1.droom.in/photos/images/eco/dashboard/order.svg"
                    className="order-icon me-3"
                />
                Order - {orderNumber}
            </Card.Title>
            <Card.Body className="text-start">
                <Card.Title className="d-flex justify-content-between">
                    Inspection Order Detail
                    <Card.Img
                        variant="top"
                        src="https://cdn1.droom.in/photos/images/eco/dashboard/call.svg"
                        className="call-icon"
                    />
                </Card.Title>
                <Card.Text className="m-0">{vehicleDetails}</Card.Text>
                <Card.Text>{customerName}</Card.Text>
                <ul className="customer-details">
                    <li className="d-flex justify-content-between">
                        <Card.Text>Customer Name</Card.Text>
                        <Card.Text>{customerName}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text className="">Customer Address</Card.Text>
                        <Card.Text>{customerAddress}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Accepted At</Card.Text>
                        <Card.Text>{inspectionStart}</Card.Text>
                    </li>
                    <li className="d-flex justify-content-between">
                        <Card.Text>Completed At</Card.Text>
                        <Card.Text>{inspectionCompleted}</Card.Text>
                    </li>
                </ul>
            </Card.Body>
        </Card>
    );
};

export default OrderBody;
