import { useRef } from 'react';
const TakeImage = ({ handleImageChange, required }) => {
    const inputRef = useRef(null);
    const handleCapture = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };
    return (
        <div className="d-flex flex-column align-items-center m-2 ">
            <input
                ref={inputRef}
                type="file"
                accept="image/*"
                required={required}
                capture="environment"
                onChange={handleImageChange}
                style={{ display: 'none' }}
            />
            <div
                onClick={handleCapture}
                className="border d-flex justify-content-center align-items-center flex-column rounded-4 fs-6 text-dark p-4 pt-2 pb-2 h-25"
            >
                <div>
                    <img
                        src="https://cdn1.acedms.com/photos/images/droomresponsive/img/content/buyers-1/photo.png"
                        alt=""
                    />
                </div>
                <div className="text-center fs-12">
                    Take <br /> Image
                </div>
            </div>
        </div>
    );
};
export default TakeImage;
