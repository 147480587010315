import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { updateImages, resetImages } from '@redux/AIRedux/aiBasedSlice';
import { useDispatch } from 'react-redux';
const SignaturePad = ({ componentDetails }) => {
    const { componentCode } = componentDetails;
    const dispatch = useDispatch();
    const sigCanvas = useRef(null);

    const clear = () => {
        dispatch(resetImages({ name: componentCode }));
        sigCanvas.current.clear();
    };

    const save = () => {
        const canvas = sigCanvas.current.getTrimmedCanvas();
        canvas.toBlob(blob => {
            dispatch(resetImages({ name: componentCode }));
            dispatch(
                updateImages({
                    name: componentCode,
                    image: new File([blob], 'png', { type: blob.type })
                })
            );
        });
    };

    return (
        <Container className="signature-board">
            <Row className="pt-2">
                <Col className="border border-2">
                    <SignatureCanvas
                        penColor="black"
                        canvasProps={{ width: 300, height: 300, className: 'sigCanvas' }}
                        ref={sigCanvas}
                        onEnd={save}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Button variant="primary" onClick={clear}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default SignaturePad;
