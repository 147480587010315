import React, { useRef } from 'react';
import SampleVideo from './SampleVideo';

const TakeVideo = ({handleVideoChange}) => {
  const inputRef = useRef(null);
  const videoRef = useRef(null);

  const handleCapture = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
      <div className='d-flex flex-column align-items-center m-2'>
        <input
          ref={inputRef}
          type='file'
          accept='video/*'
          capture='user'
          onChange={handleVideoChange}
          className="d-none"
        />
        <div onClick={handleCapture} className='border d-flex justify-content-center align-items-center flex-column rounded-4 fs-6 text-dark p-4 pt-2 pb-2 h-25'>
          <div>
            <img src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images/droomresponsive/img/content/buyers-1/photo.png`} alt="" />
          </div>
          <div className='text-center fs-12'>Take <br /> Video</div>
        </div>
        <video ref={videoRef} className="d-none w-100" style={{ maxWidth: '300px' }} controls />
      </div>
  );
};

export default TakeVideo;
