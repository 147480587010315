import axios from 'axios';
import { useHistory,useParams } from 'react-router-dom';
import SubmitButton from '@pages/SelfInspection/Common/SubmitButton';
import DemoServices from '@pages/SelfInspection/Tutorials/DemoServices';
import VideoTutorial from '@pages/SelfInspection/Tutorials/VideoTutorial';

const UnderDevelopment = () => {
    const params = useParams();
    const history = useHistory();
    const submitHandler = () => {
        axios.post(`/self-inspection/${params.orderId}/start`).then(res => {
            if (res.status == 200) {
                history.push('start');
            }
        });
    };
    return (
        <div className="text-center">
            <DemoServices />
            <VideoTutorial url="https://www.youtube.com/embed/t-Ungk0OQLQ?si=Ii7LSwjNYrqI4cm2" />
            <div className="m-4 d-flex justify-content-center">
                <SubmitButton className="px-3 py-3 text-white fs-6 " onClick={submitHandler}>
                    Proceed For Inspection
                </SubmitButton>
            </div>
        </div>
    );
};

export default UnderDevelopment;
