function DemoServices() {
    return (
        <>
            <div className="container">
                <div className="row demo-services py-3">
                    <div className="col-6 d-flex justify-content-center align-items-center">
                        <h2 className="text-start text-white">
                            Know about Self Inspection
                        </h2>
                    </div>
                    <div className="col-6">
                        <img
                            src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/demo-img.png`}
                            alt="Demo"
                            className="w-100"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DemoServices;
