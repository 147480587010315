import React from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Route, useHistory, useParams } from 'react-router-dom';
import Loader from '../pages/SelfInspection/Common/Loader';
import Layout from '../layout/self-inspection/Layout';
import { useDispatch } from 'react-redux';
import { setOrderDetail } from '@redux/FormRedux/reducers/orderSlice';
import { COMPLETED } from '../pages/SelfInspection/Order/Statuses';
import { updateImages,updateData,updateVideos } from '@redux/AIRedux/aiServiceDataSlice';
const BaseRoute = ({ component: Component, layoutProps = {}, ...props }) => {
    const { isProtected = true } = layoutProps;

    const [state, setState] = React.useState({
        loading: true,
        auth: false,
        orderStatus: null,
        aiBased:false,
    });

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const { menu } = props;

    React.useEffect(() => {
        axios.get(`/orders/${params.orderId}`).then(res => {
            if (res.data.StatusCode == 1) {
                dispatch(
                    setOrderDetail({
                        obvCategoryId: res.data.Result.obv_catgeory_id,
                        technicianId: res.data.Result.technician_id,
                        orderStatus: res.data.Result.order_status,
                        orderNumber: res.data.Result.eco_order_id,
                        vehicleDetails: res.data.Result.reg_number,
                        customerName: res.data.Result.customer_name,
                        customerAddress: res.data.Result.customer_addr,
                        aiBased: res.data.Result.aiBased,
                        inspectionStart: res.data.Result.status_start_datetime,
                        inspectionCompleted: res.data.Result.inspection_completion_datetime
                    })
                );
            }
            setState({
                loading: false,
                auth: Boolean(res?.data?.Result?.eco_order_id),
                orderStatus: res?.data?.Result?.order_status,
                aiBased: res?.data?.Result?.aiBased
            });
        });
    }, [history.location.pathname, params.orderId, dispatch]);

    React.useEffect(() => {
        if(state.aiBased){
            axios.get(`/orders/${params.orderId}/inspection-ai/servicedata`).then(res => {
                if (res.data.StatusCode == 1) {
                    dispatch(updateData({data:res.data.Result?.msite?.data}));
                    dispatch(updateImages({images:res.data.Result?.msite?.images}));
                    dispatch(updateVideos({videos: res.data.Result?.msite?.videos}));
                }
            });
        }
    }, [history.location.pathname, params.orderId, dispatch,state.aiBased]);

    React.useEffect(() => {
        if (state.loading) {
            return;
        }

        if (!state.auth && isProtected) {
            return history.push(`/self-inspection/${params.orderId}/dashboard`);
        }
        if (state.auth && isProtected && state.orderStatus === COMPLETED) {
            return history.push(`/self-inspection/${params.orderId}/completed`);
        }
    }, [state.loading, state.auth, state.orderStatus, history, params.orderId, isProtected]);

    if (state.loading) {
        return <Loader />;
    }

    return (
        <Layout {...layoutProps} menu={menu}>
            <Component {...props} />
        </Layout>
    );
};

const SelfInspectionRoute = ({ component: Component, layoutProps = {}, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                <>
                    <Helmet>
                        <title>Self Inspection | ECO</title>
                    </Helmet>
                    <BaseRoute component={Component} layoutProps={layoutProps} {...props} {...rest} />
                </>
            )}
        />
    );
};

export default SelfInspectionRoute;
